.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  background-image: radial-gradient(circle at center, rgb(249 195 222), white);
}

.header {
  display: flex;
  /* Enables flexbox layout */
  align-items: center;
  /* Centers items vertically */
  justify-content: space-between;
  /* Spreads out the child elements */
  width: 70%;
  font-size: 2em;
  padding: 20px;
  /* Add any additional styles you need for the header */
}

.footer {
  width: 100%;
  text-align: center;
}

.search-bar input {
  /* Styles for the search input */
}

.main-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  /* Adjust minimum width of cards */
  gap: 20px;
  padding: 20px;
  width: 70%;
  /* Set the main content to take up 70% of the page width */
  justify-content: center;
  /* Center the grid items horizontally */
}

/* DealCard.css */
.deal-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 20px);
  max-width: 100%;
  max-height: 450px;
  min-height: 400px;
  overflow: hidden;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background: #ffffff;
  border-radius: 10px !important;
  aspect-ratio: 2 / 5;
}

.deal-card a {
  color: inherit;
  /* This will make the color the same as the parent element */
  text-decoration: none;
  /* This will remove the underline */
}

/* Make sure to add :hover and :visited states if needed */
.deal-card a:hover,
.deal-card a:visited {
  color: inherit;
  /* Keep the color consistent on hover and when visited */
  text-decoration: none;
  /* Remove the underline on hover */
}

.deal-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.deal-image-container {
  width: 100%;
  height: 50%;
  /* Full width of the card */
  display: flex;
  justify-content: center;
  /* Center image horizontally */
  align-items: center;
  /* Center image vertically */
  background-color: #fff;
  /* Light background for the container */
}

.deal-image {
  max-width: 90%;
  /* Image can be as wide as its container */
  max-height: 90%;
  /* Image can be as tall as its container */
  object-fit: contain;
  /* Ensures the aspect ratio is maintained without cropping */
}

.deal-info {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* Align text to the start */
  flex-grow: 1;
  overflow: visible;
  text-align: left;
  /* Allows the deal info to fill the space */
}

.deal-name {
  font-size: 1.3em;
  text-align: center;
  margin-bottom: 0.5em;
}

.deal-merchant {
  font-size: 1em;
  font-weight: normal;
  text-align: left;
  margin-top: 5px;
}

.price-container {
  display: flex;
  align-items: baseline;
  /* Aligns the prices on their baseline for a cleaner look */
  justify-content: start;
  width: 100%;
  /* Ensures the container spans the full width of the card */
  margin-top: 5px;
  /* Space from the merchant name or the element above */
}

.deal-price,
.original-price {
  margin-right: 10px;
  /* Adds space between the prices */
  /* Removed the margin-top from .deal-price as it's now handled by .price-container */
}

/* Additional adjustments to ensure the text does not wrap or overflow */
.deal-price,
.original-price {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deal-price {
  font-size: 2em;
  font-weight: bold;
}

.original-price {
  font-size: 1.3em;
  text-decoration: line-through;
  color: #f60505;
}

/* end deal card */

.price-indicator {
  height: 5px;
  width: 100%;
  /* Color will be set inline based on the deal */
}

/* merchant filter */
.merchant-filter button {
  background-color: #80d9f8;
  /* Light blue color */
  border: none;
  border-radius: 20px;
  /* Rounded corners for pill shape */
  color: #333;
  /* Text color */
  cursor: pointer;
  font-size: 1.5em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 20px;
  /* Padding for larger touch area */
  transition: background-color 0.3s;
  /* Smooth transition for color change */
}

.merchant-filter button.selected {
  background-color: #885EAD;
  /* Fuchsia-blue color */
  color: white;
  /* White text for better contrast */
}

/* end merchant filter */

.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  /* Styles for the sidebar */
}

.sidebar a {
  /* Styles for the links in the sidebar */
}

.footer {
  /* Styles for the footer */
}

/* Responsive styles */
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }

  .sidebar {
    position: relative;
  }
}